import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Contact from '../Contact/contact';
import Header from '../Header/header';
import Bookings from '../Bookings/bookings';
import Services from '../Services/services';
import Testimonials from '../Testimonials/Testimonials';
import Gallery from '../Gallery/gallery';
import Team from '../Team/Team';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <> 
        <Header/>
        <Home />
        <Gallery />
        <Team />
        <div className='service-test'>
        <Services />
        <Testimonials />
        </div>
        <Contact />
        </>
        } />
         <Route path="/bookings" element={<Bookings/>} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
