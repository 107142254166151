import React, { useEffect } from "react";
import "./home.css";
import logo from "../../Images/logo3.png";
import QR from "../../Images/QR.png";
import { useNavigate } from "react-router-dom";
import video from "../../Images/video.mp4";

function Home() {
  const nav = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      // Set a threshold value (adjust as needed)
      const threshold = 1;

      // Get the current scroll position
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      // Check if the scroll position is less than the threshold
      if (scrollPosition < threshold) {
        // If yes, set the scroll position to the threshold
        window.scrollTo(0, threshold);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to a section
const scrollToSection = (sectionId) => {
  // Get the section
  const section = document.getElementById(sectionId);
  // If the section exists, scroll to it
  if (section) {
    // Get the header element
    const header = document.querySelector('.Header');

    // Set the offset to the height of the header
    let offset = 0;

    // Check if the header exists and has a height
    if (header && header.offsetHeight) {
      offset = header.offsetHeight;
    }

    // Get the top position of the section by offsetting the section's top position by the offset
    const top = section.offsetTop - offset;
    // Scroll to the desired position
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }
};

  function bookingsHandleClick() {
    nav("/bookings");
    setTimeout(() => {
      scrollToSection('bookingsSection');
    }, 100);
  }

  function reviewsHandleClick() {
    window.open('https://www.google.com/localservices/prolist?g2lbs=ANTchaPr6LTVdhSVZhrvOsbYz_z_HqeJKDqoULaBkG8FW8-HgXP2h-ePGw0XoR5Tc5fEKry4y8r8UqOHm0zmXKin_TJbQplbGS2UfuaDCtH_lRvvkK3tVbrR8N69t0TkfCiUR_DnrxuV&hl=en-GB&gl=uk&cs=1&ssta=1&oq=ak%20barbers&src=2&sa=X&q=ak%20barbers%20surbiton&ved=2ahUKEwj3tPX156WDAxVxIQYAHVgwCLMQjdcJegQIABAH&slp=MgBAAVIECAIgAIgBAJoBBgoCFxkQAQ%3D%3D&spp=Cg0vZy8xMWwzMnE5czRsOogBV2hrUUFCQUJJaE5oYXlCaVlYSmlaWEp6SUhOMWNtSnBkRzl1cWdGR0NnZ3ZiUzh3Ym1NNWVoQUJNaDhRQVNJYjE4cnR6cUt1U1hPQVRZYWVCbmp3cUlMcmlpWF9OUlMzMTNnT01oY1FBaUlUWVdzZ1ltRnlZbVZ5Y3lCemRYSmlhWFJ2Ymc9PQ%3D%3D&scp=ChBnY2lkOmJhcmJlcl9zaG9wEgAaACoLQmFyYmVyIHNob3A%3D#ts=3', '_blank');
    }

  function instagramHandleClick() {
    window.open("https://www.instagram.com/ak_barbers_/", "_blank");
  }

  function tiktokHandleClick() {
    window.open("https://www.tiktok.com/@ak_barberssurbiton", "_blank");
  }

  return (
    <div id="homeSection" className="overview">
      <div className="video">
      </div>
      <div className="border"></div>
      <div className="home">
        {/* <h1 className="homeTitle">AK BARBERS</h1> */}
      </div>
      <div className="logoHome">
      <video src={video} autoPlay loop muted playsInline>
      </video>
        {/* <img className='logoImg' src={landing} alt='logo'/> */}
        <div className="logoOverlay">
          <div className="logoImgDiv">
            <img className="logoImg" src={logo} alt="logo" />
          </div>
          <div className="home-info">
            <p className="homeDeet">
              Welcome to Tolworth's most beloved barbershop
            </p>
            <button className="bookBtn" onClick={bookingsHandleClick}>
              Book now
            </button>
            <p className="homeDeet">
            <i>*Please note all bookings are 30 minutes long. Walk-ins are welcome.*</i>
            </p>
            <div className="soc-rev">
            <div className="socials">
              <p className="socialsDeet">Follow us on social media</p>
              <span className="socialsIcons">
                <img
                  className="socialsIcon"
                  src="https://img.icons8.com/fluency/50/instagram-new.png"
                  alt="instagram"
                  onClick={instagramHandleClick}
                />
                <img
                  className="socialsIcon"
                  src="https://img.icons8.com/ios-filled/50/tiktok--v1.png"
                  alt="tiktok"
                  onClick={tiktokHandleClick}
                />
              </span>
            </div>
            <div className="reviews">
              <p className="reviewsDeet">Leave us a review</p>
              <img className="reviewsIcon" src={QR} alt="google" onClick={reviewsHandleClick}/>
            </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Home;
