import React, { useState, useEffect } from 'react';
import './edit.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDay, format } from 'date-fns';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function EditBooking({ allBookings, notify, fizanBookings, setFizanBookings }) {
    const [selectedDate, setSelectedDate] = useState([]);
    const [isHoliday, setIsHoliday] = useState(false);
    const [availableAppointments, setAvailableAppointments] = useState([
        '09:00', '09:15', '09:30', '09:45',
        '10:00', '10:15', '10:30', '10:45',
        '11:00', '11:15', '11:30', '11:45',
        '12:00', '12:15', '12:30', '12:45',
        '13:00', '13:15', '13:30', '13:45',
        '14:00', '14:15', '14:30', '14:45',
        '15:00', '15:15', '15:30', '15:45',
        '16:00', '16:15', '16:30', '16:45',
        '17:00', '17:15', '17:30', '17:45',
        '18:00', '18:30'
      ]);
    const [bookingId, setBookingId] = useState('');
    const [bookingData, setBookingData] = useState({
        firstName: '',
        surname: '',
        email: '',
        phoneNumber: '',
        barber: '',
        date: '',
        time: '',
      });
      const [showModal, setShowModal] = useState(false);
      const formatTime = (time) => format(new Date(`2000-01-01T${time}`), 'HH:mm');
      
    //   useEffect(() => {
    //     console.log('Selected Time:', bookingData.time);
    // }, [bookingData.time]);

    useEffect(() => {
      // Filter bookings for the selected date
      const filterCurrentDayBookings = async () => {
        if (!bookingData.date) return;
        if(bookingData.barber !== 'Fizan' && bookingData.barber !== 'Arslan') return;
        const formattedDate = format(bookingData.date, 'dd/MM/yyyy');
        let filteredBookings = [];
        if (bookingData.barber === 'Arslan'){
          filteredBookings = allBookings.filter(booking => booking.startDate === formattedDate);
        } else if (bookingData.barber === 'Fizan'){
          filteredBookings = fizanBookings.filter(booking => booking.startDate === formattedDate);
        } else if (bookingData.barber === ''){
          filteredBookings = [];
        }
        setSelectedDate(filteredBookings);
      };
    
      // Run the filter function whenever the barber or date changes
      filterCurrentDayBookings();
    }, [bookingData, allBookings, fizanBookings]);

      useEffect(() => {
        setAvailableAppointments(['09:00', '09:15', '09:30', '09:45',
        '10:00', '10:15', '10:30', '10:45',
        '11:00', '11:15', '11:30', '11:45',
        '12:00', '12:15', '12:30', '12:45',
        '13:00', '13:15', '13:30', '13:45',
        '14:00', '14:15', '14:30', '14:45',
        '15:00', '15:15', '15:30', '15:45',
        '16:00', '16:15', '16:30', '16:45',
        '17:00', '17:15', '17:30', '17:45',
        '18:00', '18:30'])
      }, [bookingData.date])
    

      useEffect(() => {
        // Check if it's a holiday, prevent booking if it is
        if (isHoliday) {
            setAvailableAppointments([]);
            toast.error('Arslan is on annual leave on this date. Please select another date', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [isHoliday]);
    
  
      // Effect to check for holiday bookings and adjust available time slots
      useEffect(() => {
        // Check if any booking on the selected date has the title "holiday"
        const hasHolidayBooking = selectedDate.some(booking => booking.title === 'holiday');
        
        // Set the state to indicate whether it's a holiday or not
        setIsHoliday(hasHolidayBooking);
  
        // If there's a holiday booking, adjust available time slots
        if (hasHolidayBooking) {
            setAvailableAppointments([]);
        } 
    }, [selectedDate, setIsHoliday]);

      // Set up an effect to run when currentDayBookings or setAvailableTimeSlots changes
      useEffect(() => {
        const getAvailableTimeSlots = (selectedDate) => {
            const dayOfWeek = getDay(selectedDate);
        
            switch (dayOfWeek) {
              case 0: // Sunday
                return ['09:00', '09:15', '09:30', '09:45',
                '10:00', '10:15', '10:30', '10:45',
                '11:00', '11:15', '11:30', '11:45',
                '12:00', '12:15', '12:30', '12:45',
                '13:00', '13:15', '13:30', '13:45',
                '14:00', '14:15', '14:30', '14:45',
                '15:00', '15:15', '15:30', '15:45',
                '16:00', '16:15', '16:30', '16:45',
                '17:00', '17:30'];
              case 6: // Saturday
                return ['08:00', '08:15', '08:30', '08:45',
                '09:00', '09:15', '09:30', '09:45',
                '10:00', '10:15', '10:30', '10:45',
                '11:00', '11:15', '11:30', '11:45',
                '12:00', '12:15', '12:30', '12:45',
                '13:00', '13:15', '13:30', '13:45',
                '14:00', '14:15', '14:30', '14:45',
                '15:00', '15:15', '15:30', '15:45',
                '16:00', '16:15', '16:30', '16:45',
                '17:00', '17:15', '17:30', '17:45',
                '18:00', '18:30']
              default:
                return ['09:00', '09:15', '09:30', '09:45',
                '10:00', '10:15', '10:30', '10:45',
                '11:00', '11:15', '11:30', '11:45',
                '12:00', '12:15', '12:30', '12:45',
                '13:00', '13:15', '13:30', '13:45',
                '14:00', '14:15', '14:30', '14:45',
                '15:00', '15:15', '15:30', '15:45',
                '16:00', '16:15', '16:30', '16:45',
                '17:00', '17:15', '17:30', '17:45',
                '18:00', '18:30']; // Default time slots
            }
          };

          // Calculate available time slots for the selected day
  const newAvailableTimeSlots = getAvailableTimeSlots(bookingData.date);

  // Extract booked times for the current day and format them
  const bookedTimesForCurrentDay = selectedDate.map(booking => formatTime(booking.startTime));
  // console.log('bookedTimesForCurrentDay:', bookedTimesForCurrentDay);
  const bookedTimesForCurrentDayEnd = selectedDate.map(booking => formatTime(booking.endTime));

  // Format the available time slots
  const formattedAvailableTimeSlots = newAvailableTimeSlots.map(formatTime);

  // Create a copy of formattedAvailableTimeSlots to avoid mutating the original array
  let filteredTimeSlots = [...formattedAvailableTimeSlots];

  // Iterate through each booked time for the current day
  bookedTimesForCurrentDay.forEach(bookedTime => {
    // Find the index of the included time slot in the filtered array
    const includedIndex = filteredTimeSlots.findIndex(timeSlot => timeSlot === bookedTime);

    // Check if the included index is valid
  if (includedIndex !== -1) {
    bookedTimesForCurrentDay.forEach((bookedTime, index) => {
      const startTime = new Date(`2000-01-01T${bookedTime}`).getTime();
      const endTime = new Date(`2000-01-01T${bookedTimesForCurrentDayEnd[index]}`).getTime();
      // 30 minutes before
      const startTime30Before = startTime - 29 * 60 * 1000; 
      
  
      filteredTimeSlots = filteredTimeSlots.filter(timeSlot => {
          const timeSlotTime = new Date(`2000-01-01T${timeSlot}`).getTime();
          return timeSlotTime < startTime30Before || timeSlotTime >= endTime;
      });
  });
  }
});

// Get the current date and time
const currentDate = new Date();
const selectedDateObject = new Date(bookingData.date);
const currentTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

// Filter out time slots before the current date and time
const openTimeSlots = filteredTimeSlots.filter(timeSlot => {
  // Convert the time slot to the format 'hh:mm'
  const timeSlotTime = new Date(`2000-01-01T${timeSlot}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


  // If the selected date is in the future, include all time slots
  if (selectedDateObject > currentDate) {
    return true;
  }

  // Otherwise, compare with the current time
  return timeSlotTime > currentTime;
});

// Update availableTimeSlots state
setAvailableAppointments(openTimeSlots);

    }, [selectedDate, bookingData.date, setAvailableAppointments]); 



      const handleEditBooking = () => {
        setShowModal(true);
        setBookingData({
            firstName: '',
            surname: '',
            email: '',
            phoneNumber: '',
            barber: '',
            date: '',
            time: '',
          });
          setBookingId('');
      };

      async function handleUpdateBooking(e) {
        e.preventDefault();
        try {
          const formattedTime = formatTime(bookingData.time);
          console.log('Formatted Time:', formattedTime);
          const localDate = moment(bookingData.date, 'DD/MM/YYYY').startOf('day'); 
          console.log(localDate);

          const dateWithoutTimezone = localDate.format('YYYY-MM-DDTHH:mm:ss');
          console.log(dateWithoutTimezone);

          const utcDate = moment.utc(dateWithoutTimezone).toISOString();
          console.log(utcDate);

        // Create the updated booking data with the formatted date
        const updatedBookingData = {
            ...bookingData,
            date: utcDate,
        };
            const response = await fetch(`https://ak-barbers-backend.onrender.com/bookings/${bookingId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedBookingData)
            });
            const data = await response.json();
    
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error(`Error updating booking: ${errorMessage}`);
                notify('Booking could not be updated');
            } else {
                notify('Booking updated successfully');
                console.log('Booking updated successfully');
                console.log(data);
            }
        } catch (error) {
            console.error('Error updating booking:', error);
        }
        setShowModal(false);
    }

    function handleTimeChange(e) {
        setBookingData({ ...bookingData, time: e.target.value });
        console.log(e.target.value);
      }

    return (
        <div className='edit'>
            <h1 className='edit-title'>Edit Booking</h1>
            
            <button onClick={handleEditBooking} className='edit-btn'>Edit Booking</button>
            
            {showModal && (
              <div className='edit-overlay' onClick={() => setShowModal(false)}>
        <div className="modal" >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            {/* <h2>Edit Booking</h2> */}
            <form className='edit-form'>
              <label className='label-edit'>
                Booking ID:
              </label>
                <input type="text" 
                placeholder='Booking ID'
                name="bookingId" 
                className='edit-input' 
                value={bookingId} onChange={(e) => setBookingId(e.target.value)} required/>
              <label className='label-edit'>
                First Name:
              </label>
                <input
                  type="text"
                  placeholder='First Name'
                  name="firstName"
                  className='edit-input'
                  value={bookingData.firstName}
                  onChange={(e) => setBookingData({ ...bookingData, firstName: e.target.value })}
                  required
                />
              <label className='label-edit'>
                Surname:
              </label>
                <input
                  type="text"
                  placeholder='Surname'
                  name="surname"
                  className='edit-input'
                  value={bookingData.surname}
                  onChange={(e) => setBookingData({ ...bookingData, surname: e.target.value })}
                  required
                />
              <label className='label-edit'>
                Email:
              </label>
                <input
                  type="text"
                  placeholder='Email'
                  name="email"
                  className='edit-input'
                  value={bookingData.email}
                  onChange={(e) => setBookingData({ ...bookingData, email: e.target.value })}
                  required
                />
              <label className='label-edit'>
                Phone Number:
              </label>
                <input
                  type="text"
                  placeholder='Phone Number'
                  name="phoneNumber"
                  className='edit-input'
                  value={bookingData.phoneNumber}
                  onChange={(e) => setBookingData({ ...bookingData, phoneNumber: e.target.value })}
                  pattern="\d+"  // Only allow digits
                  minLength="11" 
                  maxLength="11" 
                  required
                />
                <label for="barber" className='create-label'>Barber:</label>
                <select value={bookingData.barber}
                onChange={(e) => setBookingData({ ...bookingData, barber: e.target.value })}
                className='create-input-barber'
                required>
                <option value="">Select a Barber</option>
                <option value="Arslan">Arslan 1st chair</option>
                <option value="Fizan">Fizan 2nd chair</option>
            </select>
              <label className='label-edit'>
                Date:
              </label>
                <DatePicker placeholderText='Start Date' 
                  minDate={moment().toDate()}
                  selected={bookingData.date}
                  value={bookingData.date}
                  onChange={(date) => setBookingData({ ...bookingData, date})}
                  dateFormat="dd/MM/yyyy"
                  className='edit-input'
              required 
                />
              <label htmlFor="time" className='label-edit'>Available Times:</label>
            <select value={bookingData.time} onChange={handleTimeChange}
            className='edit-input-time'
             required
             disabled={!bookingData.barber || !bookingData.date}>>
                <option value="">Select a time</option>
                {availableAppointments.map((timeSlot, index) => (
                    <option key={index} value={formatTime(timeSlot)} disabled={isHoliday}>
                        {timeSlot}
                    </option>
                ))}
            </select>
              {/* <button type="button" onClick={() => setShowModal(false)}>Cancel</button> */}
              <button type="submit" onClick={(e) => handleUpdateBooking(e)} className='update-btn'>Update Booking</button>
            </form>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}



export default EditBooking;

