import "./team.css";
import arslan from "../../Images/Arslan.jpg";
import fizan from "../../Images/Fizan.jpg";

function Team() {
  return (
    <div className="team-overview" id="teamSection">
      <div className="team-container">
        <div className="team-title">
          <h1 className="team-heading">Meet the team</h1>
        </div>
        <div className="team">
          <div className="team-member">
            <img className="team-img" src={arslan} alt="Arslan" />
            <h2 className="team-name">Arslan</h2>
          </div>
          <div className="team-member">
            <img className="team-img" src={fizan} alt="Fizan" />
            <h2 className="team-name">Fizan</h2>
          </div>
        </div>
          <div className="team-desc">
            <p>
              We're Arslan and Fizan, two brothers with over 15 years of
              experience in the barber industry. We've mastered a wide range of
              styles and have worked with clients of all ages and genders. Our
              passion for what we do is unmatched, and we take pride in offering
              not only great haircuts but exceptional service as well.
            </p>
          </div>
      </div>
    </div>
  );
}

export default Team;
