import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import './testimonials.css';

function Testimonials() {

  const testimonials = [
    {
      name: 'Shervin M',
      testimonial: 'Been going to Arslan for almost 15 years now. I have lived abroad and in other cities and no barber has ever come close to the quality of service and cut that Arslan provides. He is a true professional and a master of his craft. I highly recommend him to anyone looking for a great haircut.'
    },
    {
      name: 'Danyal R',
      testimonial: 'Arslan is not only a great barber but a great person. He is very professional and always makes sure you are satisfied with your haircut. I have been going to him for over 10 years and I have never been disappointed, and I am so happy that he has opened his own shop.'
    },
    {
      name: 'Deen B',
      testimonial: 'Amazing barber. Always looking fresh after a trim. Highly recommend.'
    }
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,         
    autoplaySpeed: 10000,
  };

   // Reference to the React Slick slider
   const sliderRef = useRef(null);

  // Function to go to the previous slide
  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  // Function to go to the next slide
  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const stars = Array.from({ length: 5 }, (_, index) => (
    <img
      key={index}
      src='https://img.icons8.com/ios-filled/50/daa24e/star--v1.png'
      alt={`Star ${index + 1}`}
      className='star'
    />
  ));

 

  return (
    <div id="testimonialsSection" className="testimonials-overview">
      <h1 className="testimonial-title">Testimonials</h1>
      <div className="slider-container">
      <span onClick={goToPrevSlide}><img src='https://img.icons8.com/material-rounded/30/d5a153/chevron-left.png' alt='prev' className='prev'/></span>
      <Slider {...settings} className="slider" ref={sliderRef}>
        {testimonials.map((testimonial, index) => (
          <div key={index}>
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <span className="quote-icon"><BiSolidQuoteAltLeft /></span>
            <p className="testimonial-text">{testimonial.testimonial}</p>
          </div>
        ))}
      </Slider>
      <span onClick={goToNextSlide}><img src='https://img.icons8.com/material-rounded/30/d5a153/chevron-right.png' alt='next' className='next' /></span>
      </div>
      <div className='stars-div'>
      <div className='stars'>{stars}</div>;
      </div>
    </div>
  );
}

export default Testimonials;

/* 
const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const url = `http://localhost:4000/reviews`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.result && data.result.reviews) {
          setReviews(data.result.reviews);
        } else {
          console.error('No reviews found.');
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  console.log( reviews);



{reviews.length > 0 ? (
        <Slider {...settings} className="slider" ref={sliderRef}>
          {reviews.map((review, index) => (
            <div key={index}>
              <h3 className="testimonial-name">{review.author_name}</h3>
              <span className="quote-icon"><BiSolidQuoteAltLeft /></span>
              <p className="testimonial-text">{review.text}</p>
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading reviews...</p>
      )}
*/
