import './services.css';


function Services() {
  return (
    <div className='services-overview' id='servicesSection'>
    <div className='services-container'>
      <h1 className='services-title'>Services & Prices</h1>
      <img src="https://img.icons8.com/ios-filled/50/daa24e/barber-scissors.png" alt="AK Barbers" className="scissors"/>
      <p className="services-list"> Haircut..... £15</p>
      <p className="services-list">Haircut & beard shave..... £25</p>
        <p className="services-list">Skin Fade..... £18</p>
        <p className="services-list">Skin Fade & beard..... £27</p>
        <p className="services-list">Cut wash Blow Dry..... £18</p>
        <img src="https://img.icons8.com/ios-filled/50/daa24e/straight-razor.png" alt="AK Barbers" className="shave"/>
        <p className="services-list">Beard..... £15</p>
        <p className="services-list">Hot towel shave..... £17</p>
        <p className="services-list">Shape up..... £8</p>
        <img src="https://img.icons8.com/external-victoruler-outline-victoruler/50/daa24e/external-face-mask-beauty-cosmetics-victoruler-outline-victoruler.png" alt="AK Barbers" className="faceMask"/>
        <p className="services-list">Hot wax..... £8</p>
        <p className="services-list">Face mask..... £8</p>
        <p className="services-list">O.A.P..... £12</p>
        <p className="services-list">Kids Cut (Under 10’s)..... £12</p>
        <p className="services-list-warn">*O.A.Ps & KIDS FULL PRICE ON WEEKENDS* <br></br> *FACIALS WEEKDAYS ONLY*</p>
        
        </div>
    {/* <Testimonials/> */}
    </div>
  );
}

export default Services;