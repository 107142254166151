import './bookings.css';
import {useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../Header/header';
import { format } from 'date-fns';
import DeleteBooking from './Delete/delete';
import EditBooking from './Edit/edit';
import { getDay } from 'date-fns';
// import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Bookings() {
    const [allBookings, setAllBookings] = useState([])
    const [fizanBookings, setFizanBookings] = useState([])
    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('')
    const [barber, setBarber] = useState('')
    const [newEvent, setNewEvent] = useState({date: ''})
    const [selectedTime, setSelectedTime] = useState('');
    const [error, setError] = useState(null);
    const [currentDayBookings, setCurrentDayBookings] = useState([]);
    const [isHoliday, setIsHoliday] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlots] = useState([
        '09:00', '09:15', '09:30', '09:45',
        '10:00', '10:15', '10:30', '10:45',
        '11:00', '11:15', '11:30', '11:45',
        '12:00', '12:15', '12:30', '12:45',
        '13:00', '13:15', '13:30', '13:45',
        '14:00', '14:15', '14:30', '14:45',
        '15:00', '15:15', '15:30', '15:45',
        '16:00', '16:15', '16:30', '16:45',
        '17:00', '17:15', '17:30', '17:45',
        '18:00', '18:30'
      ]);
      const [isSubmitting, setSubmitting] = useState(false);
      const formatTime = (time) => format(new Date(`2000-01-01T${time}`), 'HH:mm');

    // Function to display a notification message
      const notify = useCallback((message) => {
        if (message) {
          // Define a mapping of keywords to messages
          const messageMap = {
              'Successful': 'Booking Successful',
              'failed': 'Booking failed. Please select a new time & date',
              'deleted successfully': 'Booking Deleted Successfully',
              'updated successfully': 'Booking Updated Successfully'
          };
  
          // Find the appropriate message based on the keyword
          const notificationMessage = Object.keys(messageMap).find(key => message.includes(key));
  
          // Show the notification, or the original message if no match is found
          toast(notificationMessage ? messageMap[notificationMessage] : message);
      }
  }, []);
  
      const moment = require('moment-timezone');

      const handleSubmitBooking = useCallback(async (e) => {
        e.preventDefault();

        // Disable the submit button during form submission
    if (isSubmitting) {
      return;
    }

    setSubmitting(true);
    
        if (!isEmailValid(email)) {
            setError('Please enter a valid email address.');
            return;
        }
    
        if (!isPhoneNumberValid(phoneNumber)) {
            setError('Please enter a valid phone number.');
            return;
        }

        // Assuming newEvent.date is in local time
        const localDate = moment(newEvent.date, 'DD/MM/YYYY').startOf('day'); 
        // console.log(localDate);

        const dateWithoutTimezone = localDate.format('YYYY-MM-DDTHH:mm:ss');
        // console.log(dateWithoutTimezone);

        const utcDate = moment.utc(dateWithoutTimezone).toISOString();
        // console.log(utcDate);
    
        // Continue with form submission since email and phone number are valid
        setError('');
    
        const newBooking = {
            firstName,
            surname,
            email,
            phoneNumber,
            barber,
            date: utcDate,
            time: selectedTime
        };

    try {
        const postBooking = await fetch('https://ak-barbers-backend.onrender.com/bookings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newBooking)
        });
    
        // console.log(newBooking);
        const data = await postBooking.json();
    
        // Check if the booking was successful
        if (!postBooking.ok) {
          throw new Error(data.error || 'Booking failed');
      }
    
        if (postBooking.ok) {
            setFirstName('');
            setSurname('');
            setEmail('');
            setPhoneNumber('');
            setBarber('');
            setNewEvent({ date: '' });
            setSelectedTime('');
            setError(null);
            // console.log('new booking created', data);
            // console.log(firstName, surname, email, phoneNumber, newEvent.date, selectedTime);
        }
        notify('Booking Successful');
      } catch (err){
        setError(err.message)
        setNewEvent({ date: '' });
        setSelectedTime('');
        notify(err.message)
      }
        setSubmitting(false);
    }, [setSubmitting, isSubmitting, firstName, surname, email, phoneNumber, barber, newEvent, selectedTime, setFirstName, setSurname, setEmail, setPhoneNumber, setBarber, setNewEvent, setSelectedTime, setError, notify, moment]);

// use effect for web socket to get live server updates of bookings
    useEffect(() => {
      // Establish WebSocket connection
      const ws = new WebSocket('wss://ak-barbers-backend.onrender.com/bookings');
  
      ws.onmessage = (event) => {
        const newBooking = JSON.parse(event.data);
        console.log('Received booking:', newBooking);

        setSelectedTime('')
  
        // Update the bookings state here
        setAllBookings((prevBookings) => [newBooking, ...prevBookings]);
      };
  
      // Cleanup WebSocket connection on component unmount
      return () => {
        ws.close();
      };
    }, []);


    useEffect(() => {
      // Check if it's a holiday, prevent booking if it is
      if (isHoliday) {
          setAvailableTimeSlots([]);
          toast.error('Arslan is on annual leave on this date. Please select another date', {
              position: toast.POSITION.TOP_CENTER
          });
      }
  }, [isHoliday]);
  

    // Effect to check for holiday bookings and adjust available time slots
    useEffect(() => {
      // Check if any booking on the selected date has the title "holiday"
      const hasHolidayBooking = currentDayBookings.some(booking => booking.title === 'holiday');
      
      // Set the state to indicate whether it's a holiday or not
      setIsHoliday(hasHolidayBooking);

      // If there's a holiday booking, adjust available time slots
      if (hasHolidayBooking) {
          setAvailableTimeSlots([]);
      } 
  }, [currentDayBookings]);

    useEffect(() => {
      const getBookings = async () => {
          try {
              const response = await fetch('https://ak-barbers-backend.onrender.com/bookings');
              const data = await response.json();

              // Format the dates in the received data
              const formattedBookings = data.map(booking => {
                  try {
                      return {
                          ...booking,
                          // date: format(new Date(booking.date), 'dd/MM/yyyy'),
                      };
                  } catch (error) {
                      console.error('Error formatting date:', error);
                      return booking;
                  }
              });

              setAllBookings(formattedBookings);
              
          } catch (error) {
              console.error('Error fetching bookings:', error);
          }
      };

      getBookings();
  }, []);

  useEffect(() => {
    const getFizanBookings = async () => {
        try {
            const response = await fetch('https://ak-barbers-backend.onrender.com/bookings/Fizan');
            const data = await response.json();

            // Format the dates in the received data
            const formattedBookings = data.map(booking => {
                try {
                    return {
                        ...booking,
                    };
                } catch (error) {
                    console.error('Error formatting date:', error);
                    return booking;
                }
            });

            setFizanBookings(formattedBookings);
            
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    getFizanBookings();
}, []);
  
  // useEffect(() => {
  //   console.log(allBookings);
  //   console.log(fizanBookings);
  //   console.log(barber);
  // }, [allBookings, fizanBookings, barber]);

useEffect(() => {
  // Filter bookings for the selected date
  const filterCurrentDayBookings = async () => {
    if (!newEvent.date) return;
    if(barber !== 'Fizan' && barber !== 'Arslan') return;
    const formattedDate = format(newEvent.date, 'dd/MM/yyyy');
    let filteredBookings = [];
    if (barber === 'Arslan'){
      filteredBookings = allBookings.filter(booking => booking.startDate === formattedDate);
    } else if (barber === 'Fizan'){
      filteredBookings = fizanBookings.filter(booking => booking.startDate === formattedDate);
    } else if (barber === ''){
      filteredBookings = [];
    }
    setCurrentDayBookings(filteredBookings);
  };

  // Run the filter function whenever the barber or date changes
  filterCurrentDayBookings();
}, [barber, newEvent.date, allBookings, fizanBookings]);


      useEffect(() => {
        setAvailableTimeSlots(['09:00', '09:15', '09:30', '09:45',
        '10:00', '10:15', '10:30', '10:45',
        '11:00', '11:15', '11:30', '11:45',
        '12:00', '12:15', '12:30', '12:45',
        '13:00', '13:15', '13:30', '13:45',
        '14:00', '14:15', '14:30', '14:45',
        '15:00', '15:15', '15:30', '15:45',
        '16:00', '16:15', '16:30', '16:45',
        '17:00', '17:15', '17:30', '17:45',
        '18:00', '18:30'])
      }, [newEvent.date])

      // Set up an effect to run when currentDayBookings or setAvailableTimeSlots changes
      useEffect(() => {
        const getAvailableTimeSlots = (selectedDate) => {
          // console.log(currentDayBookings);
            const dayOfWeek = getDay(selectedDate);
        
            switch (dayOfWeek) {
              case 0: // Sunday
                return ['09:00', '09:15', '09:30', '09:45',
                '10:00', '10:15', '10:30', '10:45',
                '11:00', '11:15', '11:30', '11:45',
                '12:00', '12:15', '12:30', '12:45',
                '13:00', '13:15', '13:30', '13:45',
                '14:00', '14:15', '14:30', '14:45',
                '15:00', '15:15', '15:30', '15:45',
                '16:00', '16:15', '16:30', '16:45',
                '17:00', '17:30'];
              case 6: // Saturday
                return ['08:00', '08:15', '08:30', '08:45','09:00', '09:15', '09:30', '09:45',
                '10:00', '10:15', '10:30', '10:45',
                '11:00', '11:15', '11:30', '11:45',
                '12:00', '12:15', '12:30', '12:45',
                '13:00', '13:15', '13:30', '13:45',
                '14:00', '14:15', '14:30', '14:45',
                '15:00', '15:15', '15:30', '15:45',
                '16:00', '16:15', '16:30', '16:45',
                '17:00', '17:15', '17:30', '17:45',
                '18:00', '18:30']
              default:
                return ['09:00', '09:15', '09:30', '09:45',
                '10:00', '10:15', '10:30', '10:45',
                '11:00', '11:15', '11:30', '11:45',
                '12:00', '12:15', '12:30', '12:45',
                '13:00', '13:15', '13:30', '13:45',
                '14:00', '14:15', '14:30', '14:45',
                '15:00', '15:15', '15:30', '15:45',
                '16:00', '16:15', '16:30', '16:45',
                '17:00', '17:15', '17:30', '17:45',
                '18:00', '18:30']; // Default time slots
            }
          };

          // Calculate available time slots for the selected day
  const newAvailableTimeSlots = getAvailableTimeSlots(newEvent.date);

  // Extract booked times for the current day and format them
  const bookedTimesForCurrentDay = currentDayBookings.map(booking => formatTime(booking.startTime));
  // console.log('bookedTimesForCurrentDay:', bookedTimesForCurrentDay);
  const bookedTimesForCurrentDayEnd = currentDayBookings.map(booking => formatTime(booking.endTime));
  // console.log('bookedTimesForCurrentDayEnd:', bookedTimesForCurrentDayEnd);

  // Format the available time slots
  const formattedAvailableTimeSlots = newAvailableTimeSlots.map(formatTime);

  // Create a copy of formattedAvailableTimeSlots to avoid mutating the original array
  let filteredTimeSlots = [...formattedAvailableTimeSlots];

  // Iterate through each booked time for the current day
  bookedTimesForCurrentDay.forEach(bookedTime => {
    // Find the index of the included time slot in the filtered array
    const includedIndex = filteredTimeSlots.findIndex(timeSlot => timeSlot === bookedTime);

    // Check if the included index is valid
  if (includedIndex !== -1) {
    // filteredTimeSlots.splice(includedIndex, 2);

    // console.log(bookedTimesForCurrentDay)

    bookedTimesForCurrentDay.forEach((bookedTime, index) => {
      const startTime = new Date(`2000-01-01T${bookedTime}`).getTime();
      const endTime = new Date(`2000-01-01T${bookedTimesForCurrentDayEnd[index]}`).getTime();
      // 30 minutes before
      const startTime30Before = startTime - 29 * 60 * 1000; 
   
  
      filteredTimeSlots = filteredTimeSlots.filter(timeSlot => {
          const timeSlotTime = new Date(`2000-01-01T${timeSlot}`).getTime();
          return timeSlotTime < startTime30Before || timeSlotTime >= endTime;
      });
  });
  }
});

// Get the current date and time
const currentDate = new Date();
const selectedDateObject = new Date(newEvent.date);
const currentTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


// Filter out time slots before the current date and time
const openTimeSlots = filteredTimeSlots.filter(timeSlot => {
  // Convert the time slot to the format 'hh:mm'
  const timeSlotTime = new Date(`2000-01-01T${timeSlot}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // If the selected date is in the future, include all time slots
  if (selectedDateObject > currentDate) {
    return true;
  }

  // Otherwise, compare with the current time
  return timeSlotTime > currentTime;
});

// Log the final open time slots
// console.log('Open Time Slots:', openTimeSlots);

// Update availableTimeSlots state
setAvailableTimeSlots(openTimeSlots);

    }, [currentDayBookings, newEvent.date, setAvailableTimeSlots]); 
    

      const isEmailValid = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        return emailRegex.test(email);
      };
    
      const isPhoneNumberValid = (phoneNumber) => {
        // Basic phone number validation (allow only digits)
        const phoneNumberRegex = /^\d+$/;
        const requiredLength = 11; // Change this to the desired length

        return phoneNumberRegex.test(phoneNumber) && phoneNumber.length === requiredLength;
      };

      const handleSelectChange = (event) => {
        setSelectedTime(event.target.value);
      };

    
    return (
        <div className="bookings" id='bookingsSection'>
        <Header/>
        <div className='bookings-overlay'>
        {allBookings.length === 0 ? (
      <p className='loading'>Loading Bookings...</p>
    ) : (
      <>
        <h1 className='bookings-title'>Book an Appointment</h1>
        <p className='bookings-text'>*Please note all bookings are 30 minutes long. Walk-ins are welcome.*</p>
        <form onSubmit={handleSubmitBooking} className='create-form'>
           <label for="fname" className='create-label'>First Name:</label>
            <input type="text" 
            id="fname" 
            name="fname" 
            className='create-input'
            placeholder='First Name'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required/>
            <label for="lname" className='create-label'>Surname:</label>
            <input type="text"
            id="lname"
            name="lname"
            className='create-input'
            placeholder='Surname'
            onChange={(e) => setSurname(e.target.value)}
            value={surname}
            required/>
            <label for="email" className='create-label'>Email:</label>
            <input type="text"
            id="email" 
            name="email" 
            className='create-input'
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
            required/>
            <label for="phone" className='create-label'>Phone:</label>
            <input type="text" 
            id="phone" 
            name="phone" 
            className='create-input'
            placeholder='Phone Number'
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            pattern="\d+"  // Only allow digits
            minLength="11" 
            maxLength="11" 
            required/>
            <label for="barber" className='create-label'>Barber:</label>
            <select value={barber}
            onChange={(e) => setBarber(e.target.value)}
            className='create-input-barber'
            required>
                <option value="">Select a Barber</option>
                <option value="Arslan">Arslan 1st chair</option>
                <option value="Fizan">Fizan 2nd chair</option>
            </select>
            <label for="date" className='create-label'>Date:</label>
            <DatePicker placeholderText='Date' 
            minDate={moment().toDate()}
            selected={newEvent.date}
            onChange={(date)=> setNewEvent({...newEvent, date})}
            dateFormat="dd/MM/yyyy"
            className='create-input'
            required 
            />
            <label htmlFor="time" className='create-label'>Available Times:</label>
            <select value={selectedTime} 
            onChange={handleSelectChange} 
            className='create-input-time'
            required
            disabled={!barber || !newEvent.date}>
                <option value="">Select a Time</option>
                {availableTimeSlots.map((timeSlot, index) => (
                    <option key={index} value={timeSlot} disabled={isHoliday}>
                        {timeSlot}
                    </option>
                ))}
            </select>
            
            <button type="submit" className='submit-btn' disabled={isSubmitting}>Submit</button>
            {isSubmitting ? 'Submitting...' : 'Submit'}
        </form>
        <ToastContainer />
        {error && <div>{error}</div>}
        <DeleteBooking error={error} setError={setError} notify={notify}/>
        <EditBooking allBookings={allBookings} notify={notify} fizanBookings={fizanBookings} setFizanBookings={setFizanBookings}/>
        </>
        )}
        </div>
        </div>
    );
    }

    export default Bookings;