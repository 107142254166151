import React, { useState } from 'react';
import './delete.css';

function DeleteBooking({ setError, notify }) {
  const [bookingId, setBookingId] = useState('');

  async function handleDeleteBooking() {
    try {
      const deleteBookingByID = await fetch(`https://ak-barbers-backend.onrender.com/bookings/${bookingId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!deleteBookingByID.ok) {
        const data = await deleteBookingByID.json();
        setError(data.error);
      } else {
        notify('Booking deleted successfully');
        // Booking successfully deleted
        setBookingId('');
        console.log('Booking deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting booking:', error);
      setError('Error deleting booking');
    }
  }

  return (
    <div className='delete'>
      <h1 className='delete-title'>Delete Booking</h1>
      <input
        type="text"
        placeholder="Enter Booking ID"
        className='delete-input'
        value={bookingId}
        onChange={(e) => setBookingId(e.target.value)}
      />
      <button onClick={handleDeleteBooking} className='delete-btn'>Delete Booking</button>
    </div>
  );
}

export default DeleteBooking;
