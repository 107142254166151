import "./header.css";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const [showList, setShowList] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const nav = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let menuRef = useRef();

  useEffect(() => {
    // Handle initial sizing of the window
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowMenu(screenWidth <= 945); // Change the condition here
      setShowList(false);
    };

    // Add an event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initialize the state based on the initial viewport width
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle showing/hiding the list
  const handleList = () => {
    console.log("clicked");
    setShowList(!showList); // Toggle the showList state
  };

  // Click outside the list to close it
  useEffect(() => {
    // Handle clicking outside the list
    let handler = (e) => {
      // If the menu is open and the click is outside the menu, close the menu
      if (!menuRef.current.contains(e.target)) {
        // Clicking outside the menu closes the menu and the list
        setShowList(false);
      }
    };
    // Add an event listener for mouse clicks
    document.addEventListener("mousedown", handler);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // Scroll to a section
  const scrollToSection = (sectionId) => {
    // Get the section
    const section = document.getElementById(sectionId);
    // If the section exists, scroll to it
    if (section) {
      // Get the header element
      const header = document.querySelector(".Header");

      // Set the offset to the height of the header
      let offset = 0;

      // Check if the header exists and has a height
      if (header && header.offsetHeight) {
        offset = header.offsetHeight;
      }

      // Get the top position of the section by offsetting the section's top position by the offset
      const top = section.offsetTop - offset;
      // Scroll to the desired position
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  };

  function homeHandleClick() {
    if (path !== "/") {
      nav("/");
      setTimeout(() => {
        scrollToSection("homeSection");
      }, 100);
    } else {
      // If already on the home page, just scroll to the desired section
      scrollToSection("homeSection");
    }
  }

  function bookingsHandleClick() {
    nav("/bookings");
    setTimeout(() => {
      scrollToSection("bookingsSection");
    }, 100);
  }

  // function productsHandleClick() {
  //   scrollToSection('productSection');
  // }

  function servicesHandleClick() {
    if (path !== "/") {
      nav("/");
      setTimeout(() => {
        scrollToSection("servicesSection");
        setShowList(!showList);
      }, 500);
    } else {
      // If already on the home page, just scroll to the desired section
      scrollToSection("servicesSection");
      setShowList(!showList);
    }
  }

  function teamHandleClick() {
    if (path !== "/") {
      nav("/");
      setTimeout(() => {
        scrollToSection("teamSection");
        setShowList(!showList);
      }, 500);
    } else {
      // If already on the home page, just scroll to the desired section
      scrollToSection("teamSection");
      setShowList(!showList);
    }
  }

  function testimonialsHandleClick() {
    if (path !== "/") {
      nav("/");
      setTimeout(() => {
        scrollToSection("testimonialsSection");
        setShowList(!showList);
      }, 500);
    } else {
      // If already on the home page, just scroll to the desired section
      scrollToSection("testimonialsSection");
      setShowList(!showList);
    }
  }

  function galleryHandleClick() {
    if (path !== "/") {
      nav("/");
      setTimeout(() => {
        scrollToSection("gallerySection");
        setShowList(!showList);
      }, 500);
    } else {
      // If already on the home page, just scroll to the desired section
      scrollToSection("gallerySection");
      setShowList(!showList);
    }
  }

  function contactHandleClick() {
    if (path !== "/") {
      nav("/");
      setTimeout(() => {
        scrollToSection("contactSection");
        setShowList(!showList);
      }, 500);
    } else {
      // If already on the home page, just scroll to the desired section
      scrollToSection("contactSection");
      setShowList(!showList);
    }
  }

  return (
    <div className="Header">
      <div className="navBar" ref={menuRef}>
        <h1 className="homeTitle" onClick={homeHandleClick}>
          AK BARBERS
        </h1>
        {/* <MdOutlineHome alt="logo" className="logo" onClick={homeHandleClick}/> */}
        {showMenu && (
          <AiOutlineMenu className="menuIcon" onClick={handleList} />
        )}
        {!showMenu && (
          <ul className="headerBtnList">
            <li className="headerBtn" onClick={bookingsHandleClick}>
              Book
            </li>
            {/* <li className="headerBtn" onClick={productsHandleClick}>Products</li> */}
            <li className="headerBtn" onClick={galleryHandleClick}>
              Gallery
            </li>
            <li className="headerBtn" onClick={teamHandleClick}>
              Team
            </li>
            <li className="headerBtn" onClick={servicesHandleClick}>
              Services
            </li>
            <li className="headerBtn" onClick={testimonialsHandleClick}>
              Testimonials
            </li>
            <li className="headerBtn" onClick={contactHandleClick}>
              Contact
            </li>
          </ul>
        )}
        {showList && (
          <div className="dropDown">
            <ul className="menuBtnList">
              <li className="headerBtn" onClick={bookingsHandleClick}>
                Book
              </li>
              {/* <li className="headerBtn" onClick={productsHandleClick} >Products</li> */}
              <li className="headerBtn" onClick={galleryHandleClick}>
                Gallery
              </li>
              <li className="headerBtn" onClick={teamHandleClick}>
                Team
              </li>
              <li className="headerBtn" onClick={servicesHandleClick}>
                Services
              </li>
              <li className="headerBtn" onClick={testimonialsHandleClick}>
                Testimonials
              </li>
              <li className="headerBtn" onClick={contactHandleClick}>
                Contact
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
