import React, {useState, useEffect, useCallback} from 'react';
import './gallery.css';;


function Gallery() {
    const [gallery, setGallery] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
      fetch('https://ak-barbers-backend.onrender.com/images/uploads')
        .then(response => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          return response.json(); // Assuming the response is JSON; adjust if necessary
        })
        .then(data => {
          // Handle the retrieved data (array of images, etc.)
          // console.log('Images:', data);
          setGallery(data);
        })
        .catch(error => {
          console.error('Fetch error:', error);
        });
      }, []);

    const nextClick = useCallback(() => {
        if (currentImageIndex === gallery.length - 1) {
          setCurrentImageIndex(0);
        } else {
          setCurrentImageIndex(currentImageIndex + 1);
        }
      }, [currentImageIndex, gallery]);

    useEffect(() => {
        const interval = setInterval(() => {
            nextClick();
        }, 5000);
        return () => clearInterval(interval);
        }, [nextClick]);
    
    
      function backClick() {
        if (currentImageIndex === 0) {
          // If on the first image, set to the last image
          setCurrentImageIndex(gallery.length - 1);
        } else {
          setCurrentImageIndex(currentImageIndex - 1);
        }
      }
   
      const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
      };


    // Function to get the next 8 images from the current index
    const getNextImages = () => {
        const remainingImages = gallery.slice(currentImageIndex + 1, currentImageIndex + 9);
        const startImages = gallery.slice(0, Math.max(0, 8 - remainingImages.length));
        
        // Check if there are not enough images after the current index
        if (remainingImages.length < 8) {
          const additionalImages = gallery.slice(0, 8 - remainingImages.length);
          return [...remainingImages, ...additionalImages];
        }
      
        return [...remainingImages, ...startImages];
      };
      
      
      

      return (
        <div id='gallerySection' className="gallery">
          <h1 className='gallery-title'>Gallery</h1>
          <div className="gallery-div">
            {gallery.length === 0 ? (
              <p className='gallery-loading'>Loading Gallery...</p>
            ) : (
              <div className="gallery-container">
                <span onClick={backClick}><img src='https://img.icons8.com/material-rounded/30/00000/chevron-left.png' alt='prev' className='prev-pic' /></span>
                {gallery.length > 0 && (
                  <div className="gallery-item">
                    <img
                      src={`https://ak-barbers-backend.onrender.com/${gallery[currentImageIndex].path}`}
                      alt={`gallery item ${currentImageIndex}`}
                      className="gallery-img"
                    />
                  </div>
                )}
                <span onClick={nextClick}><img src='https://img.icons8.com/material-rounded/30/00000/chevron-right.png' alt='next' className='next-pic' /></span>
              </div>
            )}
            <div className="thumbnail-row">
              {getNextImages().map((item, index) => (
                <div
                  key={index}
                  className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={`https://ak-barbers-backend.onrender.com/${item.path}`}
                    alt={`gallery item ${index}`}
                    className="thumbnail-img"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      );
              }      
    

export default Gallery